import { useState } from 'react'
import PrimaryMenu from './DesktopPrimaryMenu'
import SecondaryMenu from 'components/DesktopSecondaryMenu'
import LogoLink from 'components/LogoLink'
import { useStickyProductForm, useIsStickyPromo } from 'context/globalStore'
import cx from 'classnames'
import useScrollDirection from 'hooks/useScrollDirection'
import SearchDropDownComponent from 'components/SearchDropDown'
import usePageScrollLock from 'hooks/usePageScrollLock'

export default function DesktopNavigation({
                                            desktopPrimaryMenu,
                                            desktopSecondaryMenu,
                                            desktopPrimaryMenuV2,
                                            desktopSecondaryMenuV2,
                                            globals,
                                            homepageCta,
                                            bgColorStyle,
                                            searchConfig,
                                            isBannerHidden,
                                            isNoNavigation,
                                            isGetSubscriptionPage
                                          }) {
  const { isStickyProductFormActive } = useStickyProductForm()
  const direction = useScrollDirection()
  const [searchDropDown, setSearchDropDown] = useState(false)
  const { isStickyPromo } = useIsStickyPromo()

  usePageScrollLock(searchDropDown, 600)

  const toggleSearchDropDown = () => {
    setSearchDropDown(!searchDropDown)
  }

  const getTopPosition = () => {
    if (isStickyPromo && isBannerHidden !== false) {
      let promo = document.getElementById('dynamic-promo')
      if (promo) {
        return promo.clientHeight
      } else {
        return 35
      }
    } else {
      return 0
    }
  }

  return (
    <>
      <header
        id="desktop-nav"
        className={cx(`sticky z-10 hidden l:block`, {
          // 'pointer-events-none': isStickyProductFormActive && direction === 'down',
        })}
        style={{ top: `${getTopPosition()}px` }}
      >
        <nav
          className={cx('w-full l:px-40 xxl:px-60 h-65 z-10 transition ease-out-cubic', {
            // 'transform -translate-y-full': isStickyProductFormActive && direction === 'down',
            // 'border-b border-pattens-blue delay-75':
            // isStickyProductFormActive && direction === 'up',
            // 'shadow-top-bar': !isStickyProductFormActive,
          })}
          style={bgColorStyle}
        >
          <div className="relative w-full h-full flex justify-between items-center">
            <span
              onClick={() => {
                setSearchDropDown(false)
              }}
            >
              {!isNoNavigation && <PrimaryMenu items={desktopPrimaryMenuV2} isGetSubscriptionPage={isGetSubscriptionPage} />}
            </span>

            <LogoLink />
            <SecondaryMenu
              items={desktopSecondaryMenuV2}
              homepageCta={homepageCta}
              toggle={() => toggleSearchDropDown()}
              isNoNavigation={isNoNavigation}
            />
          </div>
          {searchDropDown && (
            <SearchDropDownComponent
              closeSearchBar={() => {
                setSearchDropDown(false)
              }}
              toggle={() => toggleSearchDropDown()}
              globals={globals}
              searchConfig={searchConfig}
            />
          )}
        </nav>
      </header>
    </>
  )
}
