import { useState } from 'react'
import DesktopLeftMenu from 'components/Navigation/Desktop/DesktopLeftMenu'
import DesktopRightMenu from 'components/Navigation/Desktop/DesktopRightMenu'
import LogoLink from 'components/LogoLink'
import { useStickyProductForm, useIsStickyPromo } from 'context/globalStore'
import cx from 'classnames'
import useScrollDirection from 'hooks/useScrollDirection'
import SearchDropDownComponent from 'components/SearchDropDown'
import usePageScrollLock from 'hooks/usePageScrollLock'

export default function DesktopNavigation({
  desktopPrimaryMenu,
  desktopSecondaryMenu,
  globals,
  homepageCta,
  bgColorStyle,
  searchConfig,
  isBannerHidden,
  isNoNavigation,
  globalNavigation,
  isGetSubscriptionPage
}) {
  const { isStickyProductFormActive } = useStickyProductForm()
  const direction = useScrollDirection()
  const [searchDropDown, setSearchDropDown] = useState( false )
  const { isStickyPromo } = useIsStickyPromo()

  usePageScrollLock( searchDropDown, 600 )

  const toggleSearchDropDown = () => {
    setSearchDropDown( !searchDropDown )
  }

  const getTopPosition = () => {
    if ( isStickyPromo && isBannerHidden !== false ) {
      let promo = document.getElementById( 'dynamic-promo' )
      if ( promo ) {
        return promo.clientHeight
      } else {
        return 35
      }
    } else {
      return 0
    }
  }

  return (
    <>
      <header
        id="desktop-nav"
        className={cx( `sticky z-10 hidden l:block`, {} )}
        style={{ top: `${getTopPosition()}px` }}
      >
        <nav
          className={cx( 'w-full l:px-40 xxl:px-60 h-65 z-10 transition ease-out-cubic' )}
          style={bgColorStyle}
        >
          <div className="relative w-full h-full flex justify-between items-center">
            <DesktopLeftMenu
              toggle={() => setSearchDropDown( false )}
              items={desktopPrimaryMenu}
              globalNavigation={globalNavigation}
              isGetSubscriptionPage={isGetSubscriptionPage}
            />
            <LogoLink/>
            <DesktopRightMenu
              globalNavigation={globalNavigation}
              items={desktopSecondaryMenu}
              homepageCta={homepageCta}
              toggle={() => toggleSearchDropDown()}
              closeSearchBar={() => {
                setSearchDropDown( false )
              }}
            />
          </div>
          {searchDropDown && (
            <SearchDropDownComponent
              closeSearchBar={() => {
                setSearchDropDown( false )
              }}
              toggle={() => toggleSearchDropDown()}
              globals={globals}
              searchConfig={searchConfig}
            />
          )}
        </nav>
      </header>
    </>
  )
}
